import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrEventActivityLogView',
  computed: {
    ...mapState('event', ['eventActivityLog'])
  }
})
export default class GtrEventActivityLogView extends Vue {
  data () {
    return {
      loading: false,
      table: {
        headers: [
          { text: 'Date', align: 'start', sortable: true, value: 'created_at' },
          { text: 'Causer', align: 'start', sortable: true, value: 'causer' },
          { text: 'Change Type', align: 'start', sortable: true, value: 'description' },
          { text: 'Item', align: 'start', sortable: true, value: 'item' },
          { text: '', sortable: false, searchable: false, value: 'actions', width: '90px' }
        ],
        items: []
      },
      activityLogToDetails: null,
      modalDetails: false,
      properties: []
    }
  }

  async mounted () {
    await this.fetchActivityLogs()
  }

  @Watch('eventActivityLog', { immediate: true })
  onEventActivityLogChange (values: any[]) {
    if (Array.isArray(values)) {
      this.$data.table.items = values
    }
  }

  onHandleShowActivityLogDetails (item: any) {
    this.$data.activityLogToDetails = item
    this.$data.modalDetails = true
    for (const [key, value] of Object.entries(item.properties.attributes)) {
      this.$data.properties.push({ prop: key, value })
    }
  }

  private async fetchActivityLogs () {
    this.$data.loading = true
    await this.$store.dispatch('event/getEventActivityLog', { event_uuid: this.$route.params.event_uuid })
    this.$data.loading = false
  }
}
